import React from "react"
import { homePagePaths, translate } from '../translate'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, navigate } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"

const PageJobs = ({ data }) => {
  const nodePage = data.nodePage
  const nodeJobs = data.nodeJobs
  return(
    <div>
        <Layout translationPaths={homePagePaths}>
          <Header/>
          <Title title={nodePage?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center space3" />

          <Footer/>
        </Layout>
    </div>
)
}

export const pageQuery = graphql`
query PageJobs ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
          title
          body {
            processed
          }
          relationships{
            field_image{
              uri{
                url
              }
            }
          }
        } 
        nodeJobs (langcode: {eq: $langcode}) {
            field_values_title
            field_values_subtitle
            field_values_body {
              processed
            }
            field_values_cta {
              title
              uri
            }
            field_oportunities_title
            field_oportunities_subtitle
            field_oportunities_body {
              processed
            }
            relationships {
              field_oportunities_photo {
                uri {
                  url
                }
              }
              field_values_photo {
                uri {
                  url
                }
              }
            }
            field_oportunities_cta {
              title
            }
          }

  }
`

export default PageJobs